import React from 'react'

const keyPress = () => {}

export class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: false,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab6: false,
      tab7: false,
      tab8: false
    };
  }

  render() {
    const {
      question1,
      question2,
      question3,
      question4,
      question5,
      question6,
      question7,
      question8,
      rponse1,
      rponse2,
      rponse3,
      rponse4,
      rponse5,
      rponse6,
      rponse7,
      rponse8
    } = this.props.data
    return (
      <section className="section-faq">
        <div className="container">
          <h2 className="extra-large">FAQ</h2>

          {question1 !== ' ' ?
          <div className="accordion">
            <label htmlFor="1" className={`accordionitem ${this.state.tab1 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab1: !this.state.tab1})}>
                <h4>{question1}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="1"/>
            <p className="hiddentext">{rponse1.rponse1}</p>
          </div> : ''}

          {question2 !== ' ' ?
          <div className="accordion">
            <label htmlFor="2" className={`accordionitem ${this.state.tab2 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab2: !this.state.tab2})}>
                <h4>{question2}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="2"/>
            <p className="hiddentext">{rponse2.rponse2}</p>
          </div> : ''}

          {question3 !== ' ' ?
          <div className="accordion">
            <label htmlFor="3" className={`accordionitem ${this.state.tab3 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab3: !this.state.tab3})}>
                <h4>{question3}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="3"/>
            <p className="hiddentext">{rponse3.rponse3}</p>
          </div> : ''}

          {question4 !== ' ' ?
          <div className="accordion">
            <label htmlFor="4" className={`accordionitem ${this.state.tab4 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab4: !this.state.tab4})}>
                <h4>{question4}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="4"/>
            <p className="hiddentext">{rponse4.rponse4}</p>
          </div> : ''}

          {question5 !== ' ' ?
          <div className="accordion">
            <label htmlFor="5" className={`accordionitem ${this.state.tab5 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab5: !this.state.tab5})}>
                <h4>{question5}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="5"/>
            <p className="hiddentext">{rponse5.rponse5}</p>
          </div> : ''}

          {question6 !== ' ' ?
          <div className="accordion">
            <label htmlFor="6" className={`accordionitem ${this.state.tab6 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab6: !this.state.tab6})}>
                <h4>{question6}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="6"/>
            <p className="hiddentext">{rponse6.rponse6}</p>
          </div> : ''}

          {question7 !== ' ' ?
          <div className="accordion">
            <label htmlFor="7" className={`accordionitem ${this.state.tab7 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab7: !this.state.tab7})}>
                <h4>{question7}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="7"/>
            <p className="hiddentext">{rponse7.rponse7}</p>
          </div> : ''}

          {question8 !== ' ' ?
          <div className="accordion">
            <label htmlFor="8" className={`accordionitem ${this.state.tab8 === true ? 'checked' : ''}`}>
              <div role="row" tabIndex={0} onKeyPress={keyPress} onClick={(e) => this.setState({tab8: !this.state.tab8})}>
                <h4>{question8}<i className="icon"></i></h4>
              </div>
            </label>
            <input type="checkbox" id="8"/>
            <p className="hiddentext">{rponse8.rponse8}</p>
          </div> : ''}
        </div>
      </section>
    )
  }
}

export default Faq
