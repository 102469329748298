import React, { useState } from 'react'
import { Link } from 'gatsby'
import Calendly from './Calendly'

function BlocCta2Cols(props) {
  const [popupCalendly, setPopupCalendly] = useState(false)

  const closePopupCalendly = () => {
    setPopupCalendly(false)
  }

  const blocCta2Columns = props.data

  return (
    <section className="section-blocCta-2columns">
      <div className="container">
        <div className="row">
          <div className="columns col-lg-6">
            <div className="box-item">
              <h2 className="medium">{blocCta2Columns.ctaTexteGauche.ctaTexteGauche}</h2>
              <Link to={blocCta2Columns.ctaUrlLienGauche}><button className="btn btn-secondary btn-blanc"><span></span>{blocCta2Columns.ctaLienGauche}</button></Link>
            </div>
          </div>
          <div className="columns col-lg-6">
            <div className="box-item">
              <h2 className="medium">{blocCta2Columns.ctaTexteDroite.ctaTexteDroite}</h2>
              <button onClick={(e) => setPopupCalendly(true)} className="btn btn-secondary btn-blanc"><span></span>{blocCta2Columns.ctaLienDroite}</button>
            </div>
          </div>
        </div>
      </div>
      <Calendly data={popupCalendly} closePopup={closePopupCalendly}/>
    </section>
  )
}

export default BlocCta2Cols
