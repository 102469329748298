import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Faq from '../components/Faq'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Slider from 'react-rangeslider'
import BlocCta2Cols from '../components/BlocCta2Cols'
import Calendly from '../components/Calendly'

export class Pricing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      switcher: false,
      language: 1,
      volume: 10,
      prixMensuelStarterPlan: '',
      prixAnnuelStarterPlan: ''
    };
  }

  componentDidMount() {
    const priceOffer2 = this.props.data.allContentfulPricing.nodes[`${this.state.language}`].offers[1]
    this.setState({
      prixMensuelStarterPlan:  priceOffer2.tarifMensuel,
      prixAnnuelStarterPlan:  priceOffer2.tarifAnnuel
    })
  }

  // Calcul des tarifs au slide du curseur
  handleOnChange = (value) => {
    const priceOffer2 = this.props.data.allContentfulPricing.nodes[`${this.state.language}`].offers[1]
    const prixMensuelStarterPlan = priceOffer2.tarifMensuel;
    const prixAnnuelStarterPlan = priceOffer2.tarifAnnuel;
    var prixMensuel = (prixMensuelStarterPlan / 10) * this.state.volume
    var prixAnnuel = (prixAnnuelStarterPlan / 10) * this.state.volume
    this.setState({
      volume: value,
      prixMensuelStarterPlan: prixMensuel.toFixed(),
      prixAnnuelStarterPlan: prixAnnuel.toFixed()
    })
    if (value < 10) {
      this.setState({
        volume: 10
      })
    }
  }

  getLanguage = (data) => {
    localStorage.setItem('language', data);
    if (data === '0') {
      this.setState({
        language: 0,
        nodeLocale: 'en-US'
      });
    } else if (data === '1') {
      this.setState({
        language: 1,
        nodeLocale: 'fr'
      });
    }
  }

  closePopupCalendly = (data) => {
    this.setState({
      popupCalendly: false
    });
  }

  componentWillMount() {
    if (typeof localStorage !== 'undefined') {
      if (localStorage.getItem('language') === '0') {
        this.setState({
          language: localStorage.getItem('language'),
          nodeLocale: 'en-US'
        });
      } else if ((localStorage.getItem('language')) === '1') {
        this.setState({
          language: 1,
          nodeLocale: 'fr'
        });
      } else {
        this.setState({
          language: 1,
          nodeLocale: 'fr'
        });
      }
    }
  }

  render() {
    const data = this.props.data
    const {
      titre,
      offers,
      headlineCreatePackage,
      descriptionCreatePackage,
      headlineGroupOfFeatures,
      groupOfFeaturesTitrePack1,
      groupOfFeaturesTitrePack2,
      groupOfFeaturesTitrePack3,
      feature1,
      feature2,
      feature3,
      feature4,
      feature5,
      feature6,
      faq,
      blocCta2Columns
    } = data.allContentfulPricing.nodes[`${this.state.language}`]
    const header = data.allContentfulSousMenu.nodes
    const postNode = data.allContentfulPricing.nodes[`${this.state.language}`]
    let { volume } = this.state

    return (
      <div>
        <Header headerContent={header} infoPage={this.props} language={this.state.language} getLanguage={this.getLanguage}/>
        <Layout data={data}>
          <SEO pagePath="/pricing" postNode={postNode} pageSEO />

          <div className="wrapper" id="wrapper-pricing">
            <main className={`pricing ${this.state.nodeLocale}`}>
              <section className="section-1">
                <div className="container">
                  <div className="row">
                    <div className="columns col-lg-6">
                      <h1 className="big-headline" id="mainHeader">{titre}</h1>
                    </div>
                    <div className="columns col-lg-6">
                      <ul className="box-switch">
                        <li>{this.state.language === '0' ? 'Yearly' : 'Annuel'}</li>
                        <li>
                          <label className="switch">
                            <input type="checkbox" defaultChecked="checked" onChange={(event) => this.setState({switcher: !this.state.switcher})}/>
                            <span className="slider round"></span>
                          </label>
                        </li>
                        <li>{this.state.language === '0' ? 'Monthly' : 'Mensuel'}</li>
                      </ul>
                    </div>
                  </div>

                  <div className="row">

                    {offers.map((item) => {
                      return (
                        <div className="columns col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12" key={item.titre}>
                          <div className="box-pack">
                            <h2 className="small text-center">{item.titre}</h2>
                            <h3 className="small-description text-center">{item.description.description}</h3>
                            <p className="price text-center">
                            {item.contentful_id === '183NXVQY3nqKeZ70bVFVUG' ?
                              <span>{this.state.switcher  === false ? `${this.state.prixMensuelStarterPlan}` : `${this.state.prixAnnuelStarterPlan}`}<sup>{item.node_locale === 'en-US' ? '$' : '€'}</sup></span> :
                            item.contentful_id === '1kyDOudmm83eS7l4bjudpp' ? <span>{item.node_locale  === 'en-US' ? 'Custom' : 'Perso'}</span> :
                              <span>{this.state.switcher  === false ? `${item.tarifMensuel}` : `${item.tarifAnnuel}`}<sup>{item.node_locale === 'en-US' ? '$' : '€'}</sup></span>
                            }
                            </p>

                            <p className="text-center small tundora">{item.noteTarif}</p>
                            {item.contentful_id === '183NXVQY3nqKeZ70bVFVUG' ?
                            <p className="text-center nbr-users">
                              {this.state.volume === 1 ?
                              <span>{this.state.volume} {this.state.language  === 0 ? `user` : `utilisateur`}</span> :
                              <span>{this.state.volume} {this.state.language  === 0 ? `users` : `utilisateurs`}</span>
                              }
                            </p> : ''}

                            {item.contentful_id === '183NXVQY3nqKeZ70bVFVUG' ?
                              <Slider
                                value={volume}
                                min={0}
                                max={100}
                                orientation="horizontal"
                                tooltip={false}
                                onChange={this.handleOnChange}
                              /> : ''}

                            <div className="box-features">
                              <h3 className="medium">
                                {item.contentful_id === 'h7l7BhRB4jwsG5vw1jQnJ' ? <div>{item.node_locale  === 'en-US' ? `Basic Features` : `Fonctionnalités Basiques`}</div> : ''}
                                {item.contentful_id === '183NXVQY3nqKeZ70bVFVUG' ? <div>{item.node_locale  === 'en-US' ? `Regular Features` : `Fonctionnalités Regular`}</div> : ''}
                                {item.contentful_id === '1kyDOudmm83eS7l4bjudpp' ? <div>{item.node_locale  === 'en-US' ? `Custom Features` : `Fonctionnalités Perso`}</div> : ''}
                              </h3>
                              <div className="features-list" dangerouslySetInnerHTML={{ __html: item.featuresList.childMarkdownRemark.html}}/>
                              <Link to="" title=""><button className="btn btn-primary"><span>{this.state.language === '0' ? 'Schedule a demo' : 'Planifier une démo'}</span></button></Link>
                            </div>
                            <p className="trial-timelapse">{this.state.language === '0' ? <span>QQ days trial<br/> Your trial expired DD days HH hours</span> : <span>QQ jours d&rsquo;essai<br/> Votre essai se termine dans DD jours et HH heures</span>}</p>
                          </div>
                        </div>
                      )
                    })}

                  </div>
                </div>
              </section>

              <section className="section-2">
                <div className="container">
                  <h2 className="big-headline">{headlineCreatePackage}</h2>
                  <p className="description">{descriptionCreatePackage}</p>
                  <p><Link to="">{this.state.language === '0' ? 'Contact us' : 'Contactez-nous'}</Link></p>
                  <table>
                    <thead>
                      <tr>
                        <th><h3 className="large">{headlineGroupOfFeatures}</h3></th>
                        <th><h3 className="large">{groupOfFeaturesTitrePack1}</h3></th>
                        <th><h3 className="large">{groupOfFeaturesTitrePack2}</h3></th>
                        <th><h3 className="large">{groupOfFeaturesTitrePack3}</h3></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: feature1.childMarkdownRemark.rawMarkdownBody.replace('###', '<h4>').replace('\nA', '</h4>') }}/></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                      </tr>
                      <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: feature2.childMarkdownRemark.rawMarkdownBody.replace('###', '<h4>').replace('\nA', '</h4>') }}/></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                      </tr>
                      <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: feature3.childMarkdownRemark.rawMarkdownBody.replace('###', '<h4>').replace('\nA', '</h4>') }}/></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                      </tr>
                      <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: feature4.childMarkdownRemark.rawMarkdownBody.replace('###', '<h4>').replace('\nA', '</h4>') }}/></td>
                        <td><span className="not-include"></span></td>
                        <td><span className="include"></span></td>
                        <td><span className="include"></span></td>
                      </tr>
                      <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: feature5.childMarkdownRemark.rawMarkdownBody.replace('###', '<h4>').replace('\nA', '</h4>') }}/></td>
                        <td><span className="not-include"></span></td>
                        <td><span className="not-include"></span></td>
                        <td><span className="include"></span></td>
                      </tr>
                      <tr>
                        <td><div dangerouslySetInnerHTML={{ __html: feature6.childMarkdownRemark.rawMarkdownBody.replace('###', '<h4>').replace('\nA', '</h4>') }}/></td>
                        <td><span className="not-include"></span></td>
                        <td><span className="not-include"></span></td>
                        <td><span className="include"></span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <Faq data={faq}/>
              <BlocCta2Cols data={blocCta2Columns}/>

              <Calendly data={this.state.popupCalendly} closePopup={this.closePopupCalendly}/>
            </main>
          </div>
        </Layout>
        <Footer getLanguage={this.getLanguage}/>
      </div>
    )
  }
}

export const query = graphql`
  query PricingQuery {
    allContentfulSousMenu {
      nodes {
        contentful_id
        node_locale
        titre
        icone {
          file {
            url
          }
        }
        baselineMenu {
          baselineMenu
        }
      }
    }
    allContentfulPricing {
      nodes {
        id
        node_locale
        titre
        metaDescription {
          metaDescription
          internal {
            content
          }
        }
        offers {
          node_locale
          contentful_id
          tarifMensuel
          tarifAnnuel
          noteTarif
          titre
          featuresList {
            childMarkdownRemark {
              html
            }
          }
          description {
            description
          }
        }
        headlineCreatePackage
        descriptionCreatePackage
        headlineGroupOfFeatures
        groupOfFeaturesTitrePack1
        groupOfFeaturesTitrePack2
        groupOfFeaturesTitrePack3
        feature1 {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        feature2 {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        feature3 {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        feature4 {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        feature5 {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        feature6 {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        faq {
          question1
          question2
          question3
          question4
          question5
          question6
          question7
          question8
          rponse1 {
            rponse1
          }
          rponse2 {
            rponse2
          }
          rponse3 {
            rponse3
          }
          rponse4 {
            rponse4
          }
          rponse5 {
            rponse5
          }
          rponse6 {
            rponse6
          }
          rponse7 {
            rponse7
          }
          rponse8 {
            rponse8
          }
        }
        blocCta2Columns {
          ctaLienGauche
          ctaLienDroite
          ctaTexteGauche {
            ctaTexteGauche
          }
          ctaTexteDroite {
            ctaTexteDroite
          }
        }
      }
    }
  }
`

export default Pricing
